import React, { memo, useCallback, useMemo, useState } from "react";
import styled from "styled-components";
import { Alert, Tag, TreeSelect } from "antd";
import dayjs from "dayjs";

import { Loading } from "components/Loading";
import { Spacer } from "components/Spacer";
import { useCorporation } from "hooks/useCorporation";
import { useShop } from "hooks/useShop";

import { CurrentSalesCard } from "./CurrentSalesCard";
import {
  useCurrentSalesCurrentSalesAndTableStatuesQuery,
  useCurrentSalesGetShopQuery,
} from "./queries";
import { TableStatusesCard } from "./TableStatusesCard";

export const currentSalesDefaultPageSize = 10;

const StyledTreeSelect = styled(TreeSelect<string>)`
  width: 100%;
`;

export const ShopCurrentSales = memo(() => {
  const [corporation] = useCorporation();
  const corporationId = corporation?.corporationId;
  const [shop, setShop] = useShop();
  const shopId = shop?.shopId;
  const [lastCurrentSalesesFetched, setLastCurrentSalesesFetched] = useState(dayjs());

  const {
    data: getShopsData,
    loading: loadingGetShops,
    error: getShopsError,
  } = useCurrentSalesGetShopQuery(
    corporationId ? { variables: { corporationId } } : { skip: true },
  );

  const {
    data: getCurrentSalesData,
    loading: loadingGetCurrentSaleses,
    error: getCurrentSalesError,
  } = useCurrentSalesCurrentSalesAndTableStatuesQuery(
    shopId
      ? {
          variables: { input: { shopId } },
          onCompleted: () => setLastCurrentSalesesFetched(dayjs()),
        }
      : { skip: true },
  );

  const currentSales =
    getCurrentSalesData?.dashboardCurrentSalesAndTableStatuses.currentSales ?? null;
  const tableStatues =
    getCurrentSalesData?.dashboardCurrentSalesAndTableStatuses.tableStatuses ?? null;

  const loading = loadingGetCurrentSaleses;
  const error = getCurrentSalesError;

  const shopTreeData = useMemo(
    () =>
      (getShopsData?.corporation[0]?.companies ?? [])
        .filter(({ shops }) => shops.length > 0)
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((company) => ({
          key: company.id,
          value: company.id,
          title: company.name,
          selectable: false,
          children: company.shops
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((shop) => ({
              key: shop.shopId,
              value: shop.shopId,
              title: shop.name,
              selectable: true,
            })),
        })),
    [getShopsData?.corporation],
  );

  const handleChangeShop = useCallback(
    (shopId: string) => {
      setShop(shopId);
    },
    [setShop],
  );

  return (
    <>
      <Spacer height={6} />

      <Tag color="processing">{lastCurrentSalesesFetched.format("YYYY/MM/DD HH:mm")} 時点</Tag>

      <Spacer height={16} />

      {error && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}

      <StyledTreeSelect
        showSearch
        treeData={shopTreeData}
        treeDefaultExpandAll
        value={shopId ?? ""}
        onChange={handleChangeShop}
        treeNodeFilterProp="title"
        placeholder="店舗を選択"
      />

      <Spacer size={20} />

      {loading && <Loading />}

      {currentSales && <CurrentSalesCard currentSales={currentSales} />}

      <Spacer size={32} />

      {currentSales && tableStatues && (
        <TableStatusesCard tableStatuses={tableStatues} unitPrice={currentSales.activeUnitSales} />
      )}
    </>
  );
});
