import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Alert } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { Timestamp } from "firebase/firestore";

import { AccessibleCompanies, AccessibleShops } from "components/Layout/DashboardLayout/types";
import { colors } from "constants/colors";

import { AvailableFrom } from "../Row/AvailableFrom";
import { Category } from "../Row/Category";
import { Message } from "../Row/Message";
import { Targets } from "../Row/Targets";
import { Title } from "../Row/Title";
import { TransformNotification } from "..";

const StyledAlert = styled(Alert)`
  position: fixed;
  left: 50%;
  top: 64px;
  width: calc(100vw - 32px);
  transform: translateX(-50%);
  margin: 16px auto;
`;

const HiddenMessageContainer = styled.div<{ isHidden: boolean }>`
  overflow-y: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
`;

const MessageContainer = styled.div`
  max-height: 400px;
  overflow-y: scroll;
`;

const LoadMoreWrapper = styled.div`
  display: flex;
  gap: 8px;
  cursor: pointer;
`;

const LoadMoreButton = styled.div`
  padding: 0;
  color: ${colors.Text.Primary};
  font-weight: 400;
  border-color: transparent;
  background-color: transparent;
  box-shadow: none;
`;

const DownIcon = styled(DownOutlined)`
  color: ${colors.Text.Primary};
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

type Props = {
  notification: TransformNotification;
  accountAccessibleCompanies: AccessibleCompanies;
  accountAccessibleShops: AccessibleShops;
  onClose: (notificationId: string, expiredAt: Timestamp) => Promise<void>;
};

export const ImportantDetailModal = memo<Props>(
  ({ notification, onClose, accountAccessibleCompanies, accountAccessibleShops }) => {
    const [isMessageOverflowing, setIsMessageOverflowing] = useState(false);
    const [isFullOpen, setIsFullOpen] = useState(false);

    const messageRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      if (!messageRef.current) return;
      setIsMessageOverflowing(messageRef.current.offsetHeight < messageRef.current.scrollHeight);
    }, [notification]);

    const openFully = useCallback(() => {
      setIsFullOpen(true);
      setIsMessageOverflowing(false);
    }, []);

    const close = useCallback(() => {
      setIsFullOpen(false);
      onClose(notification.id, notification.availableUntil);
    }, [onClose, notification.availableUntil, notification.id]);

    return (
      <StyledAlert
        type="error"
        message={
          <HeaderContainer>
            <Category isImportant={notification.isImportant} category={notification.category} />
            <Title
              title={notification.title}
              fontSize={16}
              fontWeight={notification.isUnread ? "bold" : "normal"}
            />
            <Targets
              targets={notification.targets}
              accountAccessibleCompanies={accountAccessibleCompanies}
              accountAccessibleShops={accountAccessibleShops}
            />
          </HeaderContainer>
        }
        description={
          <>
            {isFullOpen ? (
              <MessageContainer>
                <Message message={notification.message} />
              </MessageContainer>
            ) : (
              <HiddenMessageContainer ref={messageRef} isHidden={isMessageOverflowing}>
                <Message message={notification.message} />
              </HiddenMessageContainer>
            )}
            <AvailableFrom availableFrom={notification.availableFrom}>
              {isMessageOverflowing ? (
                <LoadMoreWrapper onClick={openFully} role="button">
                  <LoadMoreButton>詳細を見る</LoadMoreButton>
                  <DownIcon />
                </LoadMoreWrapper>
              ) : (
                <LoadMoreWrapper onClick={close} role="button">
                  <LoadMoreButton>閉じる</LoadMoreButton>
                </LoadMoreWrapper>
              )}
            </AvailableFrom>
          </>
        }
      />
    );
  },
);
