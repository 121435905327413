import React, { memo } from "react";
import styled from "styled-components";
import { Card, Col, Row } from "antd";
import { chunk, sum } from "lodash";

import { Spacer } from "components/Spacer";

import { TableStatues } from "../types";

import { TableCard } from "./TableCard";

type Props = {
  tableStatuses: TableStatues;
  unitPrice: number;
};

const moneyFormatter = new Intl.NumberFormat("ja");

export const getFormattedMonetaryAmount = (amount: number | null) =>
  amount === null
    ? "-"
    : !Number.isFinite(amount)
    ? "¥0"
    : `¥${moneyFormatter.format(Number(amount))}`;

const SummaryContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const TableStatusesCard = memo<Props>(({ tableStatuses, unitPrice }) => {
  const tableCount = sum(tableStatuses.map(({ tables }) => tables.length));
  const activishTableCount = sum(
    tableStatuses.map(({ tables }) => tables.filter(({ tableUser }) => tableUser).length),
  );
  const fullTableRate = tableCount === 0 ? 0 : ((activishTableCount / tableCount) * 100).toFixed(1);

  return (
    <Card>
      <div style={{ fontSize: 20, fontWeight: 600 }}>店内状況</div>

      <Spacer size={4} />
      <SummaryContainer>
        <div style={{ fontSize: 14, fontWeight: 600 }}>満卓率：</div>
        <Spacer size={4} />
        <div style={{ fontSize: 20, fontWeight: 600 }}>{fullTableRate}%</div>
        <Spacer size={8} />
        <div style={{ fontSize: 14 }}>
          ({activishTableCount}/{tableCount}卓)
        </div>

        <Spacer size={20} />

        <div style={{ fontSize: 14, fontWeight: 600 }}>客単価：</div>
        <Spacer size={4} />
        <div style={{ fontSize: 20, fontWeight: 600 }}>{getFormattedMonetaryAmount(unitPrice)}</div>
      </SummaryContainer>

      {tableStatuses.map((tableArea) => {
        const chunkedTables = chunk(tableArea.tables, 6);

        return (
          <div key={tableArea.tableAreaId}>
            <Spacer size={16} />

            <div style={{ fontSize: 16, fontWeight: 600 }}>{tableArea.tableAreaName}</div>

            <Spacer size={16} />

            {chunkedTables.map((tables, index) => (
              <>
                <Row gutter={16} key={index.toString()}>
                  {tables.map((table) => (
                    <Col span={4} key={table.tableId} flex="auto">
                      <TableCard tableName={table.tableName} tableUser={table.tableUser ?? null} />
                    </Col>
                  ))}
                </Row>
                <Spacer size={16} />
              </>
            ))}
          </div>
        );
      })}
    </Card>
  );
});
