import * as Types from '../../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const CurrentSalesGetShop = gql`
    query CurrentSalesGetShop($corporationId: uuid!) {
  corporation(where: {id: {_eq: $corporationId}}) {
    companies {
      id
      name
      shops(where: {archivedAt: {_is_null: true}}) {
        shopId
        name
      }
    }
  }
}
    `;
export const DashboardCurrentSalesList = gql`
    query DashboardCurrentSalesList($input: QueryCurrentSalesInput!) {
  dashboardCurrentSaleses(input: $input) {
    currentSaleses {
      shopId
      shopName
      currentSales
      currentUnitSales
      currentTaxExcludedSales
      currentNumberOfPeople
      currentNumberOfGroup
      activeNumberOfTableUser
      activeNumberOfPeople
      activeNumberOfGroup
      activeSales
      activeUnitSales
      tableCount
      salesBudgetAmount
    }
  }
}
    `;
export type CurrentSalesGetShopQueryVariables = Types.Exact<{
  corporationId: Types.Scalars['uuid'];
}>;


export type CurrentSalesGetShopQuery = (
  { __typename?: 'query_root' }
  & { corporation: Array<(
    { __typename?: 'corporation' }
    & { companies: Array<(
      { __typename?: 'company' }
      & Pick<Types.Company, 'id' | 'name'>
      & { shops: Array<(
        { __typename?: 'shop' }
        & Pick<Types.Shop, 'shopId' | 'name'>
      )> }
    )> }
  )> }
);

export type DashboardCurrentSalesListQueryVariables = Types.Exact<{
  input: Types.QueryCurrentSalesInput;
}>;


export type DashboardCurrentSalesListQuery = (
  { __typename?: 'query_root' }
  & { dashboardCurrentSaleses: (
    { __typename?: 'CurrentSaleses' }
    & { currentSaleses: Array<(
      { __typename?: 'CurrentSales' }
      & Pick<Types.CurrentSales, 'shopId' | 'shopName' | 'currentSales' | 'currentUnitSales' | 'currentTaxExcludedSales' | 'currentNumberOfPeople' | 'currentNumberOfGroup' | 'activeNumberOfTableUser' | 'activeNumberOfPeople' | 'activeNumberOfGroup' | 'activeSales' | 'activeUnitSales' | 'tableCount' | 'salesBudgetAmount'>
    )> }
  ) }
);


export const CurrentSalesGetShopDocument = gql`
    query CurrentSalesGetShop($corporationId: uuid!) {
  corporation(where: {id: {_eq: $corporationId}}) {
    companies {
      id
      name
      shops(where: {archivedAt: {_is_null: true}}) {
        shopId
        name
      }
    }
  }
}
    `;

/**
 * __useCurrentSalesGetShopQuery__
 *
 * To run a query within a React component, call `useCurrentSalesGetShopQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentSalesGetShopQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentSalesGetShopQuery({
 *   variables: {
 *      corporationId: // value for 'corporationId'
 *   },
 * });
 */
export function useCurrentSalesGetShopQuery(baseOptions: Apollo.QueryHookOptions<CurrentSalesGetShopQuery, CurrentSalesGetShopQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CurrentSalesGetShopQuery, CurrentSalesGetShopQueryVariables>(CurrentSalesGetShopDocument, options);
      }
export function useCurrentSalesGetShopLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentSalesGetShopQuery, CurrentSalesGetShopQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CurrentSalesGetShopQuery, CurrentSalesGetShopQueryVariables>(CurrentSalesGetShopDocument, options);
        }
export type CurrentSalesGetShopQueryHookResult = ReturnType<typeof useCurrentSalesGetShopQuery>;
export type CurrentSalesGetShopLazyQueryHookResult = ReturnType<typeof useCurrentSalesGetShopLazyQuery>;
export type CurrentSalesGetShopQueryResult = Apollo.QueryResult<CurrentSalesGetShopQuery, CurrentSalesGetShopQueryVariables>;
export const DashboardCurrentSalesListDocument = gql`
    query DashboardCurrentSalesList($input: QueryCurrentSalesInput!) {
  dashboardCurrentSaleses(input: $input) {
    currentSaleses {
      shopId
      shopName
      currentSales
      currentUnitSales
      currentTaxExcludedSales
      currentNumberOfPeople
      currentNumberOfGroup
      activeNumberOfTableUser
      activeNumberOfPeople
      activeNumberOfGroup
      activeSales
      activeUnitSales
      tableCount
      salesBudgetAmount
    }
  }
}
    `;

/**
 * __useDashboardCurrentSalesListQuery__
 *
 * To run a query within a React component, call `useDashboardCurrentSalesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardCurrentSalesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardCurrentSalesListQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDashboardCurrentSalesListQuery(baseOptions: Apollo.QueryHookOptions<DashboardCurrentSalesListQuery, DashboardCurrentSalesListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DashboardCurrentSalesListQuery, DashboardCurrentSalesListQueryVariables>(DashboardCurrentSalesListDocument, options);
      }
export function useDashboardCurrentSalesListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DashboardCurrentSalesListQuery, DashboardCurrentSalesListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DashboardCurrentSalesListQuery, DashboardCurrentSalesListQueryVariables>(DashboardCurrentSalesListDocument, options);
        }
export type DashboardCurrentSalesListQueryHookResult = ReturnType<typeof useDashboardCurrentSalesListQuery>;
export type DashboardCurrentSalesListLazyQueryHookResult = ReturnType<typeof useDashboardCurrentSalesListLazyQuery>;
export type DashboardCurrentSalesListQueryResult = Apollo.QueryResult<DashboardCurrentSalesListQuery, DashboardCurrentSalesListQueryVariables>;