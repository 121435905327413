import React, { useCallback, useState } from "react";
import { Radio, RadioChangeEvent } from "antd";

import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";

import { CurrentSalesList } from "./CurrentSalesList";
import { ShopCurrentSales } from "./ShopCurrentSales";

type CurrentSalesTab = "single" | "list";

export const CurrentSales = () => {
  const [currentSalesTab, setCurrentSalesTab] = useState<CurrentSalesTab>("single");

  const handleChangeTab = useCallback((e: RadioChangeEvent) => {
    setCurrentSalesTab(e.target.value);
  }, []);

  return (
    <DashboardLayout title="売上速報">
      <PageHeader
        title="売上速報"
        footer={null}
        extra={[
          <Radio.Group value={currentSalesTab} onChange={handleChangeTab} key="header">
            <Radio.Button value="single">店舗別</Radio.Button>
            <Radio.Button value="list">全店舗</Radio.Button>
          </Radio.Group>,
        ]}
      />

      {currentSalesTab === "single" && <ShopCurrentSales />}

      {currentSalesTab === "list" && <CurrentSalesList />}
    </DashboardLayout>
  );
};
