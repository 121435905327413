import * as Types from '../../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const CurrentSalesGetShop = gql`
    query CurrentSalesGetShop($corporationId: uuid!) {
  corporation(where: {id: {_eq: $corporationId}}) {
    companies {
      id
      name
      shops(where: {archivedAt: {_is_null: true}}) {
        shopId
        name
      }
    }
  }
}
    `;
export const CurrentSalesCurrentSalesAndTableStatues = gql`
    query CurrentSalesCurrentSalesAndTableStatues($input: CurrentSalesAndTableStatuesInput!) {
  dashboardCurrentSalesAndTableStatuses(input: $input) {
    currentSales {
      shopId
      shopName
      currentSales
      currentUnitSales
      currentTaxExcludedSales
      currentNumberOfPeople
      currentNumberOfGroup
      activeNumberOfTableUser
      activeNumberOfPeople
      activeNumberOfGroup
      activeSales
      activeUnitSales
      tableCount
      salesBudgetAmount
    }
    tableStatuses {
      tableAreaId
      tableAreaName
      tables {
        tableId
        tableName
        tableUser {
          id
          numPeople
          stayDuration
          unitPrice
          state
        }
      }
    }
  }
}
    `;
export const DashboardCurrentSalesListByStore = gql`
    query DashboardCurrentSalesListByStore($input: QueryCurrentSalesInput!) {
  dashboardCurrentSaleses(input: $input) {
    currentSaleses {
      shopId
      shopName
      currentSales
      currentUnitSales
      currentTaxExcludedSales
      currentNumberOfPeople
      currentNumberOfGroup
      activeNumberOfTableUser
      activeNumberOfPeople
      activeNumberOfGroup
      activeSales
      activeUnitSales
      tableCount
      salesBudgetAmount
    }
  }
}
    `;
export type CurrentSalesGetShopQueryVariables = Types.Exact<{
  corporationId: Types.Scalars['uuid'];
}>;


export type CurrentSalesGetShopQuery = (
  { __typename?: 'query_root' }
  & { corporation: Array<(
    { __typename?: 'corporation' }
    & { companies: Array<(
      { __typename?: 'company' }
      & Pick<Types.Company, 'id' | 'name'>
      & { shops: Array<(
        { __typename?: 'shop' }
        & Pick<Types.Shop, 'shopId' | 'name'>
      )> }
    )> }
  )> }
);

export type CurrentSalesCurrentSalesAndTableStatuesQueryVariables = Types.Exact<{
  input: Types.CurrentSalesAndTableStatuesInput;
}>;


export type CurrentSalesCurrentSalesAndTableStatuesQuery = (
  { __typename?: 'query_root' }
  & { dashboardCurrentSalesAndTableStatuses: (
    { __typename?: 'CurrentSalesAndTableStatuses' }
    & { currentSales: (
      { __typename?: 'CurrentSales' }
      & Pick<Types.CurrentSales, 'shopId' | 'shopName' | 'currentSales' | 'currentUnitSales' | 'currentTaxExcludedSales' | 'currentNumberOfPeople' | 'currentNumberOfGroup' | 'activeNumberOfTableUser' | 'activeNumberOfPeople' | 'activeNumberOfGroup' | 'activeSales' | 'activeUnitSales' | 'tableCount' | 'salesBudgetAmount'>
    ), tableStatuses: Array<(
      { __typename?: 'DashboardTableStatusesTableArea' }
      & Pick<Types.DashboardTableStatusesTableArea, 'tableAreaId' | 'tableAreaName'>
      & { tables: Array<(
        { __typename?: 'DashboardTableStatusesTable' }
        & Pick<Types.DashboardTableStatusesTable, 'tableId' | 'tableName'>
        & { tableUser?: Types.Maybe<(
          { __typename?: 'DashboardTableStatusesTableUser' }
          & Pick<Types.DashboardTableStatusesTableUser, 'id' | 'numPeople' | 'stayDuration' | 'unitPrice' | 'state'>
        )> }
      )> }
    )> }
  ) }
);

export type DashboardCurrentSalesListByStoreQueryVariables = Types.Exact<{
  input: Types.QueryCurrentSalesInput;
}>;


export type DashboardCurrentSalesListByStoreQuery = (
  { __typename?: 'query_root' }
  & { dashboardCurrentSaleses: (
    { __typename?: 'CurrentSaleses' }
    & { currentSaleses: Array<(
      { __typename?: 'CurrentSales' }
      & Pick<Types.CurrentSales, 'shopId' | 'shopName' | 'currentSales' | 'currentUnitSales' | 'currentTaxExcludedSales' | 'currentNumberOfPeople' | 'currentNumberOfGroup' | 'activeNumberOfTableUser' | 'activeNumberOfPeople' | 'activeNumberOfGroup' | 'activeSales' | 'activeUnitSales' | 'tableCount' | 'salesBudgetAmount'>
    )> }
  ) }
);


export const CurrentSalesGetShopDocument = gql`
    query CurrentSalesGetShop($corporationId: uuid!) {
  corporation(where: {id: {_eq: $corporationId}}) {
    companies {
      id
      name
      shops(where: {archivedAt: {_is_null: true}}) {
        shopId
        name
      }
    }
  }
}
    `;

/**
 * __useCurrentSalesGetShopQuery__
 *
 * To run a query within a React component, call `useCurrentSalesGetShopQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentSalesGetShopQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentSalesGetShopQuery({
 *   variables: {
 *      corporationId: // value for 'corporationId'
 *   },
 * });
 */
export function useCurrentSalesGetShopQuery(baseOptions: Apollo.QueryHookOptions<CurrentSalesGetShopQuery, CurrentSalesGetShopQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CurrentSalesGetShopQuery, CurrentSalesGetShopQueryVariables>(CurrentSalesGetShopDocument, options);
      }
export function useCurrentSalesGetShopLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentSalesGetShopQuery, CurrentSalesGetShopQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CurrentSalesGetShopQuery, CurrentSalesGetShopQueryVariables>(CurrentSalesGetShopDocument, options);
        }
export type CurrentSalesGetShopQueryHookResult = ReturnType<typeof useCurrentSalesGetShopQuery>;
export type CurrentSalesGetShopLazyQueryHookResult = ReturnType<typeof useCurrentSalesGetShopLazyQuery>;
export type CurrentSalesGetShopQueryResult = Apollo.QueryResult<CurrentSalesGetShopQuery, CurrentSalesGetShopQueryVariables>;
export const CurrentSalesCurrentSalesAndTableStatuesDocument = gql`
    query CurrentSalesCurrentSalesAndTableStatues($input: CurrentSalesAndTableStatuesInput!) {
  dashboardCurrentSalesAndTableStatuses(input: $input) {
    currentSales {
      shopId
      shopName
      currentSales
      currentUnitSales
      currentTaxExcludedSales
      currentNumberOfPeople
      currentNumberOfGroup
      activeNumberOfTableUser
      activeNumberOfPeople
      activeNumberOfGroup
      activeSales
      activeUnitSales
      tableCount
      salesBudgetAmount
    }
    tableStatuses {
      tableAreaId
      tableAreaName
      tables {
        tableId
        tableName
        tableUser {
          id
          numPeople
          stayDuration
          unitPrice
          state
        }
      }
    }
  }
}
    `;

/**
 * __useCurrentSalesCurrentSalesAndTableStatuesQuery__
 *
 * To run a query within a React component, call `useCurrentSalesCurrentSalesAndTableStatuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentSalesCurrentSalesAndTableStatuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentSalesCurrentSalesAndTableStatuesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCurrentSalesCurrentSalesAndTableStatuesQuery(baseOptions: Apollo.QueryHookOptions<CurrentSalesCurrentSalesAndTableStatuesQuery, CurrentSalesCurrentSalesAndTableStatuesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CurrentSalesCurrentSalesAndTableStatuesQuery, CurrentSalesCurrentSalesAndTableStatuesQueryVariables>(CurrentSalesCurrentSalesAndTableStatuesDocument, options);
      }
export function useCurrentSalesCurrentSalesAndTableStatuesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentSalesCurrentSalesAndTableStatuesQuery, CurrentSalesCurrentSalesAndTableStatuesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CurrentSalesCurrentSalesAndTableStatuesQuery, CurrentSalesCurrentSalesAndTableStatuesQueryVariables>(CurrentSalesCurrentSalesAndTableStatuesDocument, options);
        }
export type CurrentSalesCurrentSalesAndTableStatuesQueryHookResult = ReturnType<typeof useCurrentSalesCurrentSalesAndTableStatuesQuery>;
export type CurrentSalesCurrentSalesAndTableStatuesLazyQueryHookResult = ReturnType<typeof useCurrentSalesCurrentSalesAndTableStatuesLazyQuery>;
export type CurrentSalesCurrentSalesAndTableStatuesQueryResult = Apollo.QueryResult<CurrentSalesCurrentSalesAndTableStatuesQuery, CurrentSalesCurrentSalesAndTableStatuesQueryVariables>;
export const DashboardCurrentSalesListByStoreDocument = gql`
    query DashboardCurrentSalesListByStore($input: QueryCurrentSalesInput!) {
  dashboardCurrentSaleses(input: $input) {
    currentSaleses {
      shopId
      shopName
      currentSales
      currentUnitSales
      currentTaxExcludedSales
      currentNumberOfPeople
      currentNumberOfGroup
      activeNumberOfTableUser
      activeNumberOfPeople
      activeNumberOfGroup
      activeSales
      activeUnitSales
      tableCount
      salesBudgetAmount
    }
  }
}
    `;

/**
 * __useDashboardCurrentSalesListByStoreQuery__
 *
 * To run a query within a React component, call `useDashboardCurrentSalesListByStoreQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardCurrentSalesListByStoreQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardCurrentSalesListByStoreQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDashboardCurrentSalesListByStoreQuery(baseOptions: Apollo.QueryHookOptions<DashboardCurrentSalesListByStoreQuery, DashboardCurrentSalesListByStoreQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DashboardCurrentSalesListByStoreQuery, DashboardCurrentSalesListByStoreQueryVariables>(DashboardCurrentSalesListByStoreDocument, options);
      }
export function useDashboardCurrentSalesListByStoreLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DashboardCurrentSalesListByStoreQuery, DashboardCurrentSalesListByStoreQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DashboardCurrentSalesListByStoreQuery, DashboardCurrentSalesListByStoreQueryVariables>(DashboardCurrentSalesListByStoreDocument, options);
        }
export type DashboardCurrentSalesListByStoreQueryHookResult = ReturnType<typeof useDashboardCurrentSalesListByStoreQuery>;
export type DashboardCurrentSalesListByStoreLazyQueryHookResult = ReturnType<typeof useDashboardCurrentSalesListByStoreLazyQuery>;
export type DashboardCurrentSalesListByStoreQueryResult = Apollo.QueryResult<DashboardCurrentSalesListByStoreQuery, DashboardCurrentSalesListByStoreQueryVariables>;