import React, { memo, useMemo } from "react";
import styled from "styled-components";
import { Card, Divider, Flex } from "antd";

import { Spacer } from "components/Spacer";

import { CurrentSales } from "../types";

import { CurrentSalesBar } from "./CurrentSalesBar";

type Props = {
  currentSales: CurrentSales;
};

const moneyFormatter = new Intl.NumberFormat("ja");
const percentageFormatter = new Intl.NumberFormat("ja", {
  style: "percent",
  minimumFractionDigits: 1,
  maximumFractionDigits: 1,
});

const getFormattedMonetaryAmount = (amount: number | null) =>
  amount === null
    ? "-"
    : !Number.isFinite(amount)
    ? "¥0"
    : `¥${moneyFormatter.format(Number(amount))}`;
const getFormattedPercentage = (percentage: number | null) =>
  percentage === null
    ? "-"
    : !Number.isFinite(percentage)
    ? "0"
    : `${percentageFormatter.format(Number(percentage))}`;

const Text = styled.div`
  font-size: 20px;
  font-weight: 400;
`;
const BoldText = styled(Text)`
  font-weight: 600;
`;
const LargeBoldText = styled(Text)`
  font-size: 24px;
  font-weight: 600;
`;
const SmallText = styled(Text)`
  font-size: 14px;
`;
const SmallBoldText = styled(SmallText)`
  font-weight: 600;
`;

export const CurrentSalesCard = memo<Props>(({ currentSales: currentShopSales }) => {
  const {
    currentSales,
    currentNumberOfGroup,
    currentNumberOfPeople,
    currentUnitSales,
    salesBudgetAmount,
    activeSales,
    activeNumberOfGroup,
    activeNumberOfPeople,
    activeUnitSales,
  } = currentShopSales;

  const formattedCurrentSaleses = useMemo(() => {
    const salesAchievementRate =
      salesBudgetAmount !== undefined && salesBudgetAmount !== null && salesBudgetAmount !== 0
        ? currentSales / salesBudgetAmount
        : null;

    const paidSales = currentSales - activeSales;
    const paidNumberOfGroup = currentNumberOfGroup - activeNumberOfGroup;
    const paidNumberOfPeople = currentNumberOfPeople - activeNumberOfPeople;
    const paidUnitSales = paidNumberOfPeople > 0 ? Math.floor(paidSales / paidNumberOfPeople) : 0;

    const currentSalesText =
      currentSales !== undefined ? getFormattedMonetaryAmount(currentSales) : 0;
    const currentNumberOfGroupText = currentNumberOfGroup !== undefined ? currentNumberOfGroup : 0;
    const currentNumberOfPeopleText =
      currentNumberOfPeople !== undefined ? currentNumberOfPeople : 0;
    const currentUnitSalesText =
      currentUnitSales !== undefined ? getFormattedMonetaryAmount(currentUnitSales) : "-";

    const activeSalesText = activeSales !== undefined ? getFormattedMonetaryAmount(activeSales) : 0;
    const activeNumberOfGroupText = activeNumberOfGroup !== undefined ? activeNumberOfGroup : 0;
    const activeNumberOfPeopleText = activeNumberOfPeople !== undefined ? activeNumberOfPeople : 0;
    const activeUnitSalesText =
      activeUnitSales !== undefined ? getFormattedMonetaryAmount(activeUnitSales) : "-";

    const paidSalesText = paidSales !== undefined ? getFormattedMonetaryAmount(paidSales) : 0;
    const paidNumberOfGroupText = paidNumberOfGroup !== undefined ? paidNumberOfGroup : 0;
    const paidNumberOfPeopleText = paidNumberOfPeople !== undefined ? paidNumberOfPeople : 0;
    const paidUnitSalesText =
      paidUnitSales !== undefined ? getFormattedMonetaryAmount(paidUnitSales) : "-";

    const salesBudgetAmountText =
      salesBudgetAmount !== undefined ? getFormattedMonetaryAmount(salesBudgetAmount) : "-";
    const salesAchievementRateText =
      salesAchievementRate !== undefined ? getFormattedPercentage(salesAchievementRate) : "-";

    return {
      currentSalesText,
      currentNumberOfGroupText,
      currentNumberOfPeopleText,
      currentUnitSalesText,
      salesBudgetAmountText,
      salesAchievementRateText,
      activeSalesText,
      activeNumberOfGroupText,
      activeNumberOfPeopleText,
      activeUnitSalesText,
      paidSalesText,
      paidNumberOfGroupText,
      paidNumberOfPeopleText,
      paidUnitSalesText,
      paidSales,
    };
  }, [
    activeNumberOfGroup,
    activeNumberOfPeople,
    activeSales,
    activeUnitSales,
    currentNumberOfGroup,
    currentNumberOfPeople,
    currentSales,
    currentUnitSales,
    salesBudgetAmount,
  ]);

  const {
    currentSalesText,
    currentNumberOfGroupText,
    currentNumberOfPeopleText,
    currentUnitSalesText,
    salesBudgetAmountText,
    salesAchievementRateText,
    activeSalesText,
    activeNumberOfGroupText,
    activeNumberOfPeopleText,
    activeUnitSalesText,
    paidSalesText,
    paidNumberOfGroupText,
    paidNumberOfPeopleText,
    paidUnitSalesText,
    paidSales,
  } = formattedCurrentSaleses;

  return (
    <Card>
      <BoldText>現在の売上</BoldText>
      <Spacer size={4} />
      <Flex gap="middle" align="center">
        <LargeBoldText>{currentSalesText}</LargeBoldText>
        <SmallText>組数：{currentNumberOfGroupText}組</SmallText>
        <SmallText>客数：{currentNumberOfPeopleText}人</SmallText>
        <SmallText>客単価：{currentUnitSalesText}</SmallText>
      </Flex>
      <Spacer size={8} />
      <CurrentSalesBar
        currentSalesBarProps={{
          currentSales,
          paidSales,
          activeSales,
          salesBudgetAmount,
          salesBudgetAmountText,
          salesAchievementRateText,
        }}
      />
      <Spacer size={8} />
      <Flex gap="middle" align="center">
        <div>
          <Flex gap="small" align="center">
            <div style={{ background: "#F46F46", height: 14, width: 14, borderRadius: 5 }} />
            <SmallBoldText>会計済み</SmallBoldText>
          </Flex>
          <Spacer size={4} />
          <BoldText>{paidSalesText}</BoldText>
          <Spacer size={4} />
          <SmallText>組数：{paidNumberOfGroupText}組</SmallText>
          <SmallText>客数：{paidNumberOfPeopleText}人</SmallText>
          <SmallText>客単価：{paidUnitSalesText}</SmallText>
        </div>

        <Divider type="vertical" style={{ height: 120 }} />

        <div>
          <Flex gap="small" align="center">
            <div style={{ background: "#1677FF", height: 14, width: 14, borderRadius: 5 }} />
            <SmallBoldText>注文済み</SmallBoldText>
          </Flex>
          <Spacer size={4} />
          <BoldText>{activeSalesText}</BoldText>
          <Spacer size={4} />
          <SmallText>組数：{activeNumberOfGroupText}組</SmallText>
          <SmallText>客数：{activeNumberOfPeopleText}人</SmallText>
          <SmallText>客単価：{activeUnitSalesText}</SmallText>
        </div>
      </Flex>
    </Card>
  );
});
