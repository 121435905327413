import React from "react";
import { Alert } from "antd";

import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { ShopSelector } from "components/ShopSelector";
import { useShop } from "hooks/useShop";

import { CurrentSalesCards } from "./CurrentSalesCards";
import { useDashboardCurrentSalesQuery } from "./queries";

export const OldCurrentSales = () => {
  const [currentShop] = useShop();

  const shopId = currentShop?.shopId;

  const { data: getCurrentSalesData, error } = useDashboardCurrentSalesQuery(
    shopId ? { variables: { input: { shopIds: [shopId] } } } : { skip: true },
  );

  const currentSales = getCurrentSalesData?.dashboardCurrentSaleses.currentSaleses[0] ?? undefined;

  return (
    <DashboardLayout title="売上速報">
      <PageHeader title="売上速報" footer={<ShopSelector />} />
      {error && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}

      <CurrentSalesCards currentSales={currentSales} />
    </DashboardLayout>
  );
};
