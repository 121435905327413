import React, { memo, useMemo } from "react";
import styled from "styled-components";
import { Col, Flex, Row } from "antd";

type CurrentSalesBar = {
  currentSales: number | undefined;
  paidSales: number | undefined;
  activeSales: number | undefined;
  salesBudgetAmount: number | undefined | null;
  salesBudgetAmountText: string;
  salesAchievementRateText: string;
};
type Props = { currentSalesBarProps: CurrentSalesBar };

const Text = styled.div`
  font-size: 20px;
  font-weight: 400;
`;
const TinyText = styled(Text)`
  font-size: 12px;
`;

export const CurrentSalesBar = memo<Props>(({ currentSalesBarProps }) => {
  const {
    currentSales,
    paidSales,
    activeSales,
    salesBudgetAmount,
    salesBudgetAmountText,
    salesAchievementRateText,
  } = currentSalesBarProps;
  const currentSalesBar = useMemo(() => {
    // 棒グラフで表示する値を計算
    const salesGap =
      paidSales !== undefined &&
      activeSales !== undefined &&
      salesBudgetAmount !== undefined &&
      salesBudgetAmount !== null &&
      paidSales + activeSales < salesBudgetAmount
        ? salesBudgetAmount - (paidSales + activeSales)
        : 0;
    const barMarginLeft =
      currentSales !== undefined &&
      salesBudgetAmount !== undefined &&
      salesBudgetAmount !== null &&
      currentSales > salesBudgetAmount
        ? (salesBudgetAmount / currentSales) * 100
        : 100;
    return { salesGap, barMarginLeft };
  }, [currentSales, paidSales, activeSales, salesBudgetAmount]);
  const { salesGap, barMarginLeft } = currentSalesBar;

  return (
    <>
      <Flex justify="space-between">
        <Flex>
          <TinyText>目標達成率：</TinyText>
          <TinyText
            style={
              salesBudgetAmount !== null &&
              salesBudgetAmount !== undefined &&
              currentSales !== undefined &&
              currentSales >= salesBudgetAmount
                ? { color: "#52C41A" }
                : {}
            }
          >
            {salesAchievementRateText}
          </TinyText>
        </Flex>
        <TinyText>
          売上目標：
          {salesBudgetAmountText}
        </TinyText>
      </Flex>
      <Row
        style={{
          borderStyle: "solid",
          borderColor: "#F0F0F0",
          borderRadius: 10,
          borderWidth: 0.7,
          overflow: "hidden",
        }}
      >
        <Col flex={paidSales} style={{ background: "#F46F46", height: 30 }}></Col>
        <Col flex={activeSales} style={{ background: "#1677FF" }}></Col>
        <Col flex={salesGap} style={{ background: "white" }}></Col>
      </Row>
      <Flex>
        <TinyText style={{ color: "#999999" }}>0%</TinyText>
        <TinyText
          style={{
            color: "#999999",
            marginLeft: `${barMarginLeft}%`,
            transform: "translateX(-100%)",
          }}
        >
          100%
        </TinyText>
      </Flex>
    </>
  );
});
