// NOTE: ADYEN_MERCHANT_ACCOUNT_IDSは dinii-self-backend/src/modules/online-payment/lib/merchant-account.ts を引用している
// {@link https://github.com/dinii-inc/dinii-self-all/blob/e24d48d0d9aeaf7724935eae898902fb112411c4/packages/dinii-self-backend/src/modules/online-payment/lib/merchant-account.ts#L38-L69}
export const ADYEN_MERCHANT_ACCOUNT_IDS =
  import.meta.env.APP_ENV === "beta"
    ? [
        "Dinii_FB_Beta",
        "Dinii_FB_GFG_Beta",
        "Dinii_FB_GFS_Beta",
        "Dinii_FB_GGS_Beta",
        "Dinii_FB_GSG_Beta",
        "Dinii_FB_GSS_Beta",
        "Dinii_FB_SFG_Beta",
        "Dinii_FB_SFS_Beta",
        "Dinii_FB_SGG_Beta",
        "Dinii_FB_SGS_Beta",
        "Dinii_FB_SSG_Beta",
        "Dinii_FB_SSS_Beta",
        "Dinii_Travel_Beta",
      ]
    : [
        "Dinii_FB",
        "Dinii_FB_GFG",
        "Dinii_FB_GFP",
        "Dinii_FB_GFS",
        "Dinii_FB_GGP",
        "Dinii_FB_GGS",
        "Dinii_FB_GPG",
        "Dinii_FB_GPP",
        "Dinii_FB_GPS",
        "Dinii_FB_GSG",
        "Dinii_FB_GSS",
        "Dinii_FB_PFG",
        "Dinii_FB_PFP",
        "Dinii_FB_PFS",
        "Dinii_FB_PGG",
        "Dinii_FB_PGP",
        "Dinii_FB_PGS",
        "Dinii_FB_PPG",
        "Dinii_FB_PPP",
        "Dinii_FB_PPS",
        "Dinii_FB_SFG",
        "Dinii_FB_SFS",
        "Dinii_FB_SGG",
        "Dinii_FB_SGS",
        "Dinii_FB_SSG",
        "Dinii_FB_SSS",
        "Dinii_Travel",
      ];
