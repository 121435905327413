import React, { memo, useCallback, useMemo } from "react";
import { Col, DatePicker, Row, Select } from "antd";
import dayjs from "dayjs";
import { uniq } from "lodash";
import { isNotNullable } from "util/type/primitive";

import { UpdateFilterConditionFunctionType } from "hooks/useFilterConditions";
import { DateString, dayjsToDateString } from "libs/DateString";

export type FilterConditions = {
  gmoBankAccountId?: string;
  // NOTE: 両端を含む
  from?: DateString;
  to?: DateString;
};

type Props = {
  gmoBankAccountIds: string[];
  getGmoBankAccountDetail: (
    gmoBankAccountId: string,
  ) => { accountNumber: string; gmoBankAccountId: string } | undefined;
  filterConditions: FilterConditions;
  updateFilterCondition: UpdateFilterConditionFunctionType<FilterConditions>;
};

export const AdyenPaymentDepositFilter = memo<Props>(
  ({ gmoBankAccountIds, getGmoBankAccountDetail, filterConditions, updateFilterCondition }) => {
    const handleUpdateFilterCondition = useCallback<
      UpdateFilterConditionFunctionType<FilterConditions>
    >((values) => updateFilterCondition(values), [updateFilterCondition]);

    const gmoBankOptions = useMemo(
      () =>
        uniq(gmoBankAccountIds)
          .map((gmoBankAccountId) => {
            const detail = getGmoBankAccountDetail(gmoBankAccountId);
            if (!detail) {
              return null;
            }
            return {
              label: detail.accountNumber,
              value: detail.gmoBankAccountId,
            };
          })
          .filter(isNotNullable),
      [gmoBankAccountIds, getGmoBankAccountDetail],
    );

    const range = useMemo<[dayjs.Dayjs | null, dayjs.Dayjs | null]>(
      () => [
        filterConditions.from ? dayjs(filterConditions.from) : null,
        filterConditions.to ? dayjs(filterConditions.to) : null,
      ],
      [filterConditions],
    );

    return (
      <Row gutter={[16, 16]} align="middle">
        <Col>
          <DatePicker.RangePicker
            value={range}
            onCalendarChange={(range) => {
              const startingDate = range?.[0]?.startOf("day");
              const closingDate = range?.[1]?.endOf("day");
              handleUpdateFilterCondition({
                from: startingDate ? dayjsToDateString(startingDate) : undefined,
                to: closingDate ? dayjsToDateString(closingDate) : undefined,
              });
            }}
          />
        </Col>
        <Col>
          <Select<string>
            allowClear
            placeholder="口座番号を選択"
            value={filterConditions.gmoBankAccountId}
            onChange={(gmoBankAccountId) => handleUpdateFilterCondition({ gmoBankAccountId })}
            optionFilterProp="label"
            options={gmoBankOptions}
          />
        </Col>
      </Row>
    );
  },
);
